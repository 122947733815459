

.pure-img-responsive {
  max-width: 100%;
  height: auto;
}


/*
Add transition to containers so they can push in and out.
*/
#layout,
#menu,
.menu-link {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

a{
  text-decoration: none;
  color: #FFFFFF;
}

/*
This is the parent `<div>` that contains the menu and the content area.
*/
#layout {
  position: relative;
 /* border:1px solid yellow;*/
}
#layout.active #menu {
  left: 150px;
  width: 150px;
}

#layout.active .menu-link {
  left: 150px;
}
/*
The content `<div>` is where all your content goes.
*/
.content {
  margin: 0 auto;
  padding: 0 2em;
  max-width: 800px;
  margin-bottom: 50px;
  line-height: 1.6em;
}

.header {
  margin: 0;
  color: #333;
  text-align: center;
  padding: 2.5em 2em 0;
  border-bottom: 10px solid #eee;

}

.header-right {
  margin: 0;
  color: #333;
  text-align: right;
  padding: 1em;
  border-bottom: 1px solid #eee;
}

.header h1 {
  margin: 0.2em 0;
  font-size: 3em;
  font-weight: 300;
}
.header h2 {
  font-weight: 300;
  color: #ccc;
  padding: 0;
  margin-top: 0;
}

.content-subhead {
  margin: 50px 0 20px 0;
  font-weight: 300;
  color: #888;
}



/*
The `#menu` `<div>` is the parent `<div>` that contains the `.pure-menu` that
appears on the left side of the page.
*/

#menu {
  margin-left: -150px; /* "#menu" width */
  width: 170px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000; /* so the menu or its navicon stays above all content */
  background: #191818;
  overflow-y: auto;
  padding-top: 0px;
}
/*
All anchors inside the menu should be styled like this.
*/
#menu a {
  color: #999;
  border: none;
  padding: 0.6em  1em 0.6em;
  font-size: 1.2em;
}

/*
Remove all background/borders, since we are applying them to #menu.
*/
#menu .pure-menu,
#menu .pure-menu ul {
  border: none;
  background: transparent;
}

/*
Add that light border to separate items into groups.
*/
#menu .pure-menu ul,
#menu .pure-menu .menu-item-divided {
  border-top: 1px solid #333;
}
/*
Change color of the anchor links on hover/focus.
*/
#menu .pure-menu li a:hover,
#menu .pure-menu li a:focus {
  background: #0A1237;
  border-bottom: 1px solid #87A4DE;
  color:white;
}

/*
This styles the selected menu item `<li>`.
*/
#menu .pure-menu-selected,
#menu .pure-menu-heading {
  /*background: #1f8dd6;*/
  background: #293B5D;
}


/*
This styles a link within a selected menu item `<li>`.
*/
#menu .pure-menu-selected a {
  color: #fff;
}

/*
This styles the menu heading.
*/
#menu .pure-menu-heading {
  font-size: 170%;
  color: #fff;
  margin: 0;
}

/* -- Dynamic Button For Responsive Menu -------------------------------------*/




.menu-link {
  position: fixed;
  display: block; /* show this only on small screens */
  top: 0;
  left: 0; /* "#menu width" */
  background: #000;
  background: rgba(0,0,0,0.7);
  font-size: 10px; /* change this value to increase/decrease button size */
  z-index: 10;
  width: 2em;
  height: auto;
  padding: 2.1em 1.6em;
}


.menu-link:hover,
.menu-link:focus {
  background: #000;
}

.menu-link span {
  position: relative;
  display: block;
}

.menu-link span,
.menu-link span:before,
.menu-link span:after {
  background-color: #fff;
  pointer-events: none;
  width: 100%;
  height: 0.2em;
}

.menu-link span:before,
.menu-link span:after {
  position: absolute;
  margin-top: -0.6em;
  content: " ";
}

.menu-link span:after {
  margin-top: 0.6em;
}


/* -- Responsive Styles (Media Queries) ------------------------------------- */

/*
Hides the menu at `48em`, but modify this based on your app's needs.
*/
@media (min-width: 48em) {

  .header,
  .content {
    padding-left: 20em;
    padding-right: 2em;
  }
/*controla del layout central*/
  #layout {
    padding-left: 150px; /* left col width "#menu" */
    padding-top: 25px;
   /* background: cyan;*/
    left: 0;
  }
 /*titulo de administraciónde usuarios*/
  #lbl_title_admin_users,#lbl_title_admin_roles,
  #lbl_title_admin_tokens,#lbl_title_admin_existencias{
    font-size: 1rem;
  }
  /*distancia del lado izquierdo de la venta del menu*/
  #menu {
    left: 150px;
    background: linear-gradient(187deg, rgba(29,41,76,1) 0%, rgba(27,39,73,1) 35%);
  }

  .menu-link {
    position: fixed;
    left: 150px;
    display: none;
  }

  #layout.active .menu-link {
    left: 150px;
    border-bottom: 1px solid #38496A;
  }
}

@media (max-width: 48em) {
  /* Only apply this when the window is small. Otherwise, the following
  case results in extra padding on the left:
      * Make the window small.
      * Tap the menu to trigger the active state.
      * Make the window large again.
  */
  #layout.active {
    position: relative;
    left: -200px;
    padding-left: -200px;

  }
  #layout {
    padding-left: 0px; /* left col width "#menu" */
    padding-top: 25px;
  }
  #menu{
    margin-left: -200px;
  }
}

.pure-menu.pure-menu-fixed {
  /* Fixed menus normally have a border at the bottom. */
  border-bottom: 1px solid black;
  /* I need a higher z-index here because of the scroll-over effect. */
  z-index: 4;
  background: linear-gradient(187deg, rgba(29,41,76,1) 0%, rgba(27,39,73,1) 35%);
}

.home-menu .pure-menu-heading {
  color: white;
  font-weight: 400;
  font-size: 120%;
}

.pure-menu-heading, .pure-menu-link {
  padding: 0.5em 1em;
}

.button-success,
.button-error,
.button-warning,
.button-secondary {
  color: white;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-success {
  background: rgb(28, 184, 65);
  /* this is a green */
}

.button-error {
  background: rgb(202, 60, 60);
  /* this is a maroon */
}

.button-warning {
  background: rgb(223, 117, 20);
  /* this is an orange */
}

.button-secondary {
  background: rgb(66, 184, 221);
  /* this is a light blue */
}

.pure-menu-list {
 color: white;
}

.pure-menu.pure-menu-fixed{
  background-color: black;
  padding: 1.3em;
}

.toast {
  float: right;
  margin-top: 30px;

}

.form-checks{
  text-align: right;
}

.hand{
  cursor: pointer;
}


/**MODAL */

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  background: #38496A;
  color:white;
}

.field_set{
  border: 1px #EAEBEC solid;
  padding: 15px 10px 0px 20px;
}

.rdt_TableCol {
  background-color: #38496A;
  color:white;
  font-size:small ;
}
.pipe{
  color:silver;
}


.img_logo_welcome img{
  margin: 0 auto;
  width: 520px;
  border: 0.3rem solid #0A1237;
  -webkit-box-shadow: 7px 8px 24px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 7px 8px 24px 1px rgba(0,0,0,0.75);
  box-shadow: 7px 8px 24px 1px rgba(0,0,0,0.75);
}

.img_logo_welcome_mobile {
  width: 15rem;
  height: 20rem;
  margin-left: -80px;
  border: 0.3rem solid #0A1237;
  -webkit-box-shadow: 7px 8px 24px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 7px 8px 24px 1px rgba(0,0,0,0.75);
  box-shadow: 7px 8px 24px 1px rgba(0,0,0,0.75);
}

.img_rotate_mobile{
  margin: 0 auto;
  width: 10rem;
  height: 10rem;
}

#layout {
  padding: -50px; /* left col width "#menu" */
}


@media (max-width:  767px) {

  .h1, h1 {
    font-size: 1.4em!important;
  }
  .h5, h5 {
    font-size: 1em!important;
  }
  #menu{
    width: 18%;
    margin: 0;
  }
  #menu a {
    font-size: xx-small;
  }
  #layout {

    padding-left: 150px; /* left col width "#menu" */
    padding-top: 25px;
  /*background: orange;*/
    left: 0;
  }
  .img_logo_welcome img {
    width: 150%;
  }
  .header-right {
    font-size: .8em;
  }
  .pure-menu-heading, .pure-menu-link {
    padding: 0em 0.2em;
  }
  #menu .pure-menu-heading {
    height: 53px;
  }
  img {
    width: 50px;
  }
  label {
    font-size: .8em;
  }
  .btn {
    font-size: .8em!important;
    margin: 20px 0px;
  }
  input, optgroup, select, textarea {
    font-size: .8em!important;
  }
  .form-select {
    margin: 20px 0px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer {
  background: linear-gradient(187deg, rgba(29,41,76,1) 0%, rgba(27,39,73,1) 35%);
  color: #fefefe;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 1em;
  text-align: right;
}

.footer .heading {
  color: #fefefe;
  width: 90%;
  text-transform: uppercase;
  margin: 0 auto;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.footer .content {
  display: flex;
  justify-content: space-evenly;
  margin: 1.5rem;
}

.footer .content p {
  margin-bottom: 1.3rem;
}

.footer .content a {
  text-decoration: none;
  color: #fefefe;
}

.footer .content a:hover {
  border-bottom: 1px solid #971717;
}

.footer .content h4 {
  margin-bottom: 1.3rem;
  font-size: 19px;
}

footer {
  text-align: right;
  margin-bottom: 2rem;
}

footer hr {
  margin: 2rem 0;
}